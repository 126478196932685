@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500;600;700&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Exo 2', sans-serif;
  color: #1E1E1E;
  background: #ffffff;
  margin: 0;
  padding: 0;
}

h1,h2 {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.display-none {
  display: none !important;
}

.menu-open {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  max-width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.container {
  padding: 0 16px;
  max-width: 1173px;
  margin: 0 auto;
}

.block-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #1E1E1E;
}

@media screen and (max-width: 1129px) {
  .container {
    max-width: 640px;
  }
  .block-title {
    font-size: 24px;
    line-height: 29px;
  }
}

@media screen and (max-width: 600px) {
  .block-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .mySwiper-car-park {
    padding-top: 25px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin-left: -15px !important;
    padding-bottom: 14px !important;

    .swiper-scrollbar-drag {
      background: #FD6120;;
    }

    .swiper-scrollbar {
      position: absolute;
      left: 4% !important;
      bottom: -7px;
      z-index: 50;
      height: 5px;
      width: 98%;
    }
  }
}

.MuiPopover-paper {
  top: 69px !important;
}
.MuiList-padding {
  width: 58px;
  padding: 0 !important;
}
.MuiSelect-icon {
  display: none !important;
}
.MuiSelect-select:focus {
  background-color: initial !important;
}
.MuiListItem-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: center !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  background: #F6F6F6 !important;
}

.MuiTooltip-tooltip {
  background-color: #FFFFFF !important;
  box-shadow: 0px 14px 32px rgba(199, 203, 206, 0.5);
  border-radius: 8px !important;
  padding: 10px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1E1E1E !important;
  font-family: 'Exo 2', sans-serif !important;
  max-width: 209px !important;
}

.MuiTooltip-arrow {
  color: #FFFFFF !important;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
}

video[poster]{
  height: 100%;
  width: 100%;
}
