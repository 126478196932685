.convenient-storage {
  margin-top: 56px;

  &-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    margin-top: 32px;
  }

  &-wrapper {
    display: flex;
    align-items: flex-start;
  }

  &_items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 8px;
    margin-top: 50px;
  }

  &_item {
    max-width: 73%;
    margin-bottom: 33px;

    &-img {
      height: 55px;
    }

    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1E1E1E;
    }

  }
}

.forbidden {
  max-width: 396px;
  width: 100%;
  padding: 24px 35px 24px 27px;
  margin-left: 112px;
  border: 2px solid #FF0000;
  border-radius: 8px;

  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    max-width: 270px;

    svg {
      margin-left: 2px;
      margin-bottom: -3px;
    }
  }

  &-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 50px;
    margin-top: 37px;
  }
  &-item {
    max-width: 50%;

    &-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-top: 16px;
    }
  }
}

.help-transit {
  background: #F7FDFD;
  box-shadow: 0px 14px 32px rgba(199, 203, 206, 0.7);
  border-radius: 8px;
  margin-top: 88px;

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-bg {
    max-width: 746px;
    width: 100%;
    height: 256px;
    background-image: url('../../../assets/help-trasit.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &-content {
    margin-left: 100px;
    padding: 40px 42px 40px 0;
  }
  &-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #1E1E1E;
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
    margin-top: 10px;
  }

  &-button {
    margin-top: 24px;
    background: #FD6120;
    box-shadow: 0px 9px 20px rgba(253, 97, 32, 0.5);
    border-radius: 8px;
    max-width: 256px;
    width: 100%;
    text-align: center;
    padding: 12px 24px 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      transition: 0.3s;
      box-shadow: none;
    }
  }
}

.convenient-storage-inform {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  margin-top: 47px;

  &-item {
    background: #F6F6F6;
    border-radius: 6.16279px;
    padding: 10px;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #1E1E1E;
    }

    &-text {
      margin-top: 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      color: #1E1E1E;
    }
  }
}

.wishes {
  background: #F6F6F6;
  border-radius: 8px;
  padding: 32px 43px;
  margin-top: 48px;

  &-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #1E1E1E;
  }

  &-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #1E1E1E;
    margin-top: 16px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 601px) {
  .convenient-storage {
    margin-top: 6px;
    &-title {
      font-size: 18px;
      line-height: 22px;
    }
    &_items {
      margin-top: 19px;
    }
    &_item {
      margin-bottom: 2px;
      &-img {
        max-width: 24px;
        height: 31px;
      }
      &-text {
        font-size: 8px;
        line-height: 11px;
      }
    }
  }

  .forbidden {
    margin-left: 46px;
    max-width: 210px;
    margin-top: 18px;
    border: 1.06387px solid #FF0000;
    border-radius: 4.25547px;
    padding: 13px 13px 12px 13px;
    &-title {
      font-size: 12px;
      line-height: 14px;
      max-width: 142px;
      svg {
        max-width: 10px;
        margin-bottom: -6px;
      }
    }
    &-items {
      margin-top: 12px;
      row-gap: 20px;
      column-gap: 23px;
    }
    &-item {
      max-width: 63%;
      &-icon {
        max-width: 24px;
      }
      &-text {
        font-size: 8px;
        line-height: 11px;
        margin-top: 8px;
      }
    }
  }
  .help-transit {
    margin-top: 47px;

    &-title {
      font-size: 18px;
      line-height: 22px;
    }
    &-text {
      font-size: 8px;
      line-height: 11px;
      margin-top: 5px;
    }
    &-bg {
      height: 137px;
      max-width: 406px;
    }
    &-content {
      margin-left: 42px;
      padding: 21px 21px 25px 0;
    }
    &-button {
      border-radius: 4px;
      margin-top: 7px;
      font-size: 8px;
      line-height: 10px;
      padding: 6px 24px 7px;
    }
  }
  .convenient-storage-inform {
    margin-top: 23px;
    column-gap: 5px;

    &-item {
      padding: 5px;
      &-top {

      }
      &-text {
        font-size: 7px;
        line-height: 9px;
        margin-top: 6px;
      }
      &-title {
        font-size: 8px;
        line-height: 10px;
      }
      &-icon {
        max-width: 16px;
      }
    }
  }
  .wishes {
    margin-top: 24px;
    padding: 17px 22px;
    &-title {
      font-size: 24px;
      line-height: 29px;
    }
    &-text {
      font-size: 10px;
      line-height: 11px;
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 600px) {
  .convenient-storage {
    margin-top: 55px;

    &-title {
      font-size: 24px;
      line-height: 29px;
      margin-top: 0;
    }

    &-wrapper {
      flex-direction: column;
    }

    &_items {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 29px;
    }
    &_item {
      max-width: 100%;
      margin-bottom: 29px;

      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
        max-width: 53%;
      }
    }
  }

  .forbidden {
    margin-left: 0;
    border: none;
    padding: 0;
    max-width: 100%;
    margin-top: 22px;

    &-title {
      svg {
        margin-bottom: -2px;
        margin-left: 4px;
      }
    }

    &-items {
      margin-top: 25px;
      row-gap: 28px;
    }
    &-item {
      max-width: 117px;
    }
  }

  .help-transit {
    margin-top: 50px;

    &-wrapper {
      flex-direction: column;
    }
    &-bg {
      height: 274px;
      background-image: url('../../headers/slide/gruzoperevozkiMob.svg');
      background-size: inherit;
      background-position: 66% 39%;
    }
    &-content {
      padding: 30px 16px 16px;
      margin-left: 0;
      width: 100%;
    }
    &-title {
      font-size: 24px;
      line-height: 29px;
    }
    &-text {
      margin-top: 9px;
    }
    &-button {
      max-width: 100%;
    }
  }
  .convenient-storage-inform {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
    margin-top: 55px;

    &-item-top {
      align-items: initial;
    }
    &-item {
      padding: 10px 10px 14px;

      &-icon {
        img {
          width: 45px;
        }
      }
    }

    &-item-title {
      font-size: 20px;
      line-height: 24px;
      margin-top: 14px;
    }

    &-item-text {
      font-size: 14px;
      line-height: 20px;
      max-width: 216px;
    }
  }
  .wishes {
    padding: 16px;
    margin-top: 61px;
    &-title {
      font-size: 24px;
      line-height: 29px;
    }

    &-text {
      font-size: 14px;
      line-height: 20px;
      margin-top: 14px;
    }
  }
}
