.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;
}

.back-card {

  .card-img {
    margin-top: 28px;
  }
}

.card {
  background: #FFFFFF;
  box-shadow: 0px 14px 32px rgba(199, 203, 206, 0.7);
  border-radius: 8px;
  max-width: 271px;
  height: 460px;
  width: 100%;
  padding: 14px 19px 19px 19px;
  margin-bottom: 16px;
  &-price {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  &-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    max-width: 188px;
  }
  &-img {
    margin-top: 24px;
    height: 103px;
    img {
      max-width: 100%;
    }
  }
  &-params {
    display: inline-flex;
    align-items: center;
    background: #F6F6F6;
    border-radius: 6.90132px;
    padding: 9px;
    margin-top: 16px;


    &_item {
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: #3D4D59;
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      span {
        &:last-child {
          margin-left: 5px;
        }
      }

      &:nth-child(2) {
        padding: 0 7px;
      }

      &:nth-child(1) {
        padding-right: 7px;
        border-right: 0.862665px solid #CFD3D7;
      }

      &:nth-child(3) {
        padding-left: 7px;
        border-left: 0.862665px solid #CFD3D7;
      }
    }
  }
  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-top: 16px;
  }
  &-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 6px;
    height: 55px;
  }
  &-button-more {
    background: #FD6120;
    box-shadow: 0px 4px 10px rgba(253, 97, 32, 0.4);
    border-radius: 8px;
    padding: 12px 0 14px;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    font-weight: 700;
    border: none;
    margin-top: 36px;
    transition: 0.3s;

    &:hover {
      box-shadow: none;
      transition: 0.3s;
    }
  }
  &-dop-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }
  &-dop-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-top: 8px;
    color: #000000;
  }
  &-dop__buttons {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
  &-dop__back {
    border: 1.88175px solid #BEC5D0;
    border-radius: 7.52698px;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-dop__order {
    background: #70AA29;
    box-shadow: 0px 4px 10px rgba(112, 170, 41, 0.4);
    border-radius: 8px;
    padding: 12px 0 14px;
    margin-left: 8px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    font-weight: 700;
    transition: 0.3s;
    &:hover {
      box-shadow: none;
      transition: 0.3s;
    }
  }
  &-dop_params {
    margin-top: 25px;

    &-item {
      font-weight: 600;
      font-size: 12px;
      line-height: 10px;
      color: #3D4D59;
      letter-spacing: 0.08em;
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        &:first-child {
          width: 25px;
        }

        &:last-child {
          margin-left: auto;
        }
      }
    }
  }
  &-dop_type {
    display: inline-flex;
    background: #F6F6F6;
    border-radius: 6.90132px;
    padding: 6px;
    &-item {
      font-weight: 600;
      font-size: 9px;
      line-height: 10px;
      color: #3D4D59;
      letter-spacing: 0.08em;
      display: flex;
      align-items: center;
      div {
        &:last-child {
          margin-left: 4px;
        }
      }

      &:first-child {
        padding-right: 5px;
        margin-right: 5px;
        border-right: 0.862665px solid #CFD3D7;
      }
    }
  }
  &-dop-top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.card-isPackage {
  height: auto;
}



@media screen and (max-width: 1129px) and (min-width: 601px) {
  .cards {
    margin-top: 23px;
  }
  .card {
    max-width: 148px;
    padding: 9px 7px;
    height: 238px;
    box-shadow: 0px 5px 10px rgb(199 203 206 / 70%);

    &-price {
      font-size: 8px;
      line-height: 10px;
    }
    &-desc {
      font-size: 7.20135px;
      line-height: 10px;
      margin-top: 5px;
      max-width: 95px;
    }
    &-params {
      margin-top: 8px;
      padding: 1px 4px;
      border-radius: 3.54992px;
      &_item {
        font-size: 5.14382px;
        line-height: 5px;

         span:last-child {
           margin-left: 3px;
         }

        svg {
          max-width: 7px;
        }

        &:nth-child(1) {
          padding-right: 3px;
        }

        &:nth-child(2) {
          padding: 0 3px;
        }

        &:nth-child(3) {
          padding-left: 3px;
        }
      }
    }

    &-img {
      margin-top: 9px;
      height: 63px;
    }
    &-title {
      font-size: 12.3452px;
      line-height: 15px;
      margin-top: 3px;
    }

    &-text {
      font-size: 7.20135px;
      line-height: 10px;
      margin-top: 3px;
      height: 35px;
    }
    &-button-more {
      margin-top: 8px;
      font-size: 7.20135px;
      line-height: 10px;
      border-radius: 4.11506px;
      padding: 7px 0 8px;
    }

    &-dop-title {
      font-size: 10px;
    }
    &-dop_type {
      padding: 5px 3px;
      border-radius: 4.11506px;
      &-item {
        font-size: 5px;
        &:first-child {
          padding-right: 2px;
          margin-right: 2px;
        }

        div {
          &:last-child {
            margin-left: 3px;
          }
        }

        svg {
          max-width: 8px;
          height: 8px;
          margin-bottom: -2px;
        }
      }
    }

    &-dop-text {
      font-size: 7.20135px;
      line-height: 10px;
      margin-top: 3px;
    }
    .back-card .card-img {
      margin-top: 4px;
    }
    &-dop_params {
      margin-top: 9px;
      &-item {
        font-size: 5.14382px;
        line-height: 5px;
        margin-bottom: 4px;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
    &-dop__buttons {
      margin-top: 6px;
    }
    &-dop__back {
      width: 23px;
      height: 23px;
      border-radius: 4px;
      border: 1.02876px solid #BEC5D0;
    }
    &-dop__order {
      font-size: 7.20135px;
      line-height: 10px;
      border-radius: 4.11506px;
      padding: 6px 0 7px;
    }
  }
  .card-isPackage {
    height: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .cards {
    margin-top: 24px;
    margin-bottom: 18px;
  }
  .card {
    max-width: initial;
    margin-bottom: 13px;

    &-title {
      margin-top: 22px;
    }

    &-button-more {
      margin-top: 27px;
    }
  }
}
